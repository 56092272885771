const colors = {
  white: '#fff',
  textLight: '#fff',
  textDark: '#1e1a1a',
  background: '#ECAE49',
  backgroundLight: '#F5F5F5',
  backgroundDark: '#83623c',
  actionPrimary: '#4c94b7',
  actionPrimaryLight: '#4c94b7',
  actionSecondary: '#ECAE49',
};

const theme = {
  colors,
};

export default theme;
